.footer {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: var(--dropshadow);
  gap: 35px;
  justify-content: center;
  padding: 50px 30px 30px;
  position: relative;
  width:100%;
}

.footerTitle {
  color: var(--primary);
}

.footerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 4%;
}

.footerContentItem {
  color: var(--primary);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  
}

.footerContentRowItem{
  margin: 0px;
}

.footerLink{
  margin: 0px;
  color: var(--primary);
  text-decoration: none;
}

.footerSocials {
  width: 100%;
  display: flex;
  flex-direction: row;  
  position: relative;
  gap: 50px;
}

.icon {
  height: 50px;
  position: relative;
  width: 50px;
  cursor: pointer;
}


@media (max-width: 1500px) {
  .footerContentItem{
    flex-direction: column;
  }

}

@media (max-width: 1080px) {
  .footerSocials{
    gap:30px;
  }

  .icon {
    height: 30px;
    width: 30px;
  }
}

@media (max-width: 825px) {

  .footerSocials{
    gap:25px;
  }
}

@media (max-width: 630px) {

  .footerSocials{
    gap:25px;
  }
}

@media (max-width: 570px) {
  .footerTitle{
    text-align: center;
  }

  .footerContent{
    flex-direction: column;
    gap: 15px;
  }

  .footerSocials{
    gap:25px;
  }
}
