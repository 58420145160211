/************************************ Fonts ***********************************/
.title {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-Desktop-title-text);
  font-weight: var(--font-weight-title);
}

.heading {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-Desktop-heading-text);
  font-weight: var(--font-weight-heading);
}

.subheading {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-Desktop-subHeading-text);
  font-weight: var(--font-weight-subHeading);
}

.boldText {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-Desktop-bold-text);
  font-weight: var(--font-weight-bold);
}

.regularText {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-Desktop-regular-text);
  font-weight: var(--font-weight-regular);
  line-height: 200%;
}

@media screen and (max-width: 750px) {
  .title {
    font-size: var(--font-size-Tablet-title-text);
  }

  .heading {
    font-size: var(--font-size-Tablet-heading-text);
  }

  .subheading {
    font-size: var(--font-size-Tablet-subHeading-text);
  }

  .boldText {
    font-size: var(--font-size-Tablet-bold-text);
  }

  .regularText {
    font-size: var(--font-size-Tablet-regular-text);
  }
}

@media screen and (max-width: 450px) {
  .title {
    font-size: var(--font-size-Mobile-title-text);
  }

  .heading {
    font-size: var(--font-size-Mobile-heading-text);
  }

  .subheading {
    font-size: var(--font-size-Mobile-subHeading-text);
  }

  .boldText {
    font-size: var(--font-size-Mobile-bold-text);
  }

  .regularText {
    font-size: var(--font-size-Mobile-regular-text);
  }
}
