/************************************ Desktop ***********************************/
.home {
  width: 100%;
  background-color: var(--white);
  flex-direction: column;
  padding-top: 50px;
  letter-spacing: normal;
  overflow: hidden;

  background-image: url(../../assets/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.body {
  display: flex;
  flex-direction: column;
  width: 75%;
  padding-top: 50px;
  padding-bottom: var(--padding-Desktop-Section);
  gap: var(--gap-Desktop-Section);
}

.infoSection {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.titleGroup {
  width: 80%;
  text-align: center;
  font-style: italic;
}

.titleGroup .heading {
  color: var(--primary);
}

.descriptionGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10%;
}

.optionsSection {
  display: flex;
  margin-left: 5%;
}

.startTestButton {
  box-shadow: var(--dropshadow);
  padding: 15px;
  width: 50%;
  margin-left: 15%;
}

/************************************ Tablet ***********************************/
@media screen and (max-width: 750px) {
  .body {
    padding-bottom: var(--padding-Tablet-Section);
    gap: var(--gap-Tablet-Section);
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .infoSection {
    align-items: center;
    width: 85%;
  }

  .descriptionGroup {
    margin-left: 0px;
  }

  .optionsSection {
    margin: 0px;
    width: 80%;
    align-items: center;
    justify-content: center;
  }

  .startTestButton {
    margin-left: 0px;
  }
}

/************************************ Mobile ***********************************/
@media screen and (max-width: 450px) {
  .home{
    padding-top: 25px;
  }

  .body {
    padding-bottom: var(--padding-Mobile-Section);
    gap: var(--gap-Mobile-Section);
  }

  .startTestButton{
    width: 80%;
  }
}

