@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,200;0,300;0,400;0,600;1,700;1,900&display=swap");
body {
  margin: 0;
  line-height: normal;
}

:root {
  /* Fonts */
  --font-family-inter: "Inter";

  /* Font Sizes */
  --font-size-Desktop-title-text: 24px;
  --font-size-Desktop-heading-text: 50px;
  --font-size-Desktop-subHeading-text: 25px;
  --font-size-Desktop-bold-text: 18px;
  --font-size-Desktop-regular-text: 16px;

  --font-size-Tablet-title-text: 21px;
  --font-size-Tablet-heading-text: 33px;
  --font-size-Tablet-subHeading-text: 21px;
  --font-size-Tablet-bold-text: 16px;
  --font-size-Tablet-regular-text: 14px;

  --font-size-Mobile-title-text: 19px;
  --font-size-Mobile-heading-text: 30px;
  --font-size-Mobile-subHeading-text: 19px;
  --font-size-Mobile-bold-text: 14px;
  --font-size-Mobile-regular-text: 13px;

  /* Font Weights */
  --font-weight-title: 400;
  --font-weight-heading: 900;
  --font-weight-subHeading: 700;
  --font-weight-bold: 600;
  --font-weight-regular: 300;
  --font-weight-light: 200;


  /* Gaps */
  --gap-Desktop-Section: 100px;
  --gap-Tablet-Section: 75px;
  --gap-Mobile-Section: 50px;


  /* Paddings */
  --padding-Desktop-Section: 250px;
  --padding-Tablet-Section: 200px;
  --padding-Mobile-Section: 150px;

  /* Colour Pallet */
  --red: #ef0000;
  --primary: #019317;
  --black: #000000;
  --white: #ffffff;
  --black30: rgba(0, 0, 0, 0.3);

  /* Effects */
  --dropshadow: 0px 10px 65px #00000040;
}

