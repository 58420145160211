.body {
  display: flex;
  flex-direction: column;
  background-image: url(../../assets/backgroundDark.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 100vh;
}

.quiz {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25px 75px;
  gap: 5%;
}

.leftSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 35vw;
  min-width: 300px;
  padding: 50px 50px;
  height: fit-content;
}

.imageContainer {
  width: 300px;
  height: 300px;
  justify-content: center;
  align-items: center;
}

.questionImage {
  width: 300px;
  height: 300px;
  border-radius: 20px;
}

.leftSectionButtonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hintText {
  text-align: justify;
  width: 100%;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 60vw;
}

.statusGroup {
  width: 100%;
}

.question {
  text-align: center;
}

.multipleChoiceQuestionsGroup {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.multipleChoiceOption {
  background-color: white;
  box-shadow: var(--dropshadow);
  border-radius: 20px;
  padding: 15px 30px 15px 50px;
  width: 100%;
  text-align: justify;
  line-height: 100%;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.multipleChoiceOption:hover {
  background-color: #f2f2f2;
}

.multipleChoiceOption:active {
  background-color: #e0e0e0;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.correctAnswer {
  background-color: rgba(28, 134, 65, 0.7);
  box-shadow: var(--dropshadow);
  border-radius: 20px;
  padding: 15px 30px 15px 50px;
  width: 100%;
  text-align: justify;
  line-height: 100%;
}

.incorrectAnswer {
  background-color: rgba(247, 78, 78, 0.6);
  box-shadow: var(--dropshadow);
  border-radius: 20px;
  padding: 15px 30px 15px 50px;
  width: 100%;
  text-align: justify;
  line-height: 100%;
}
.mobileSection {
  display: none;
}

.footer {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--white);
  padding: 5px 10px;
}

/************************************ Tablet ***********************************/
@media screen and (max-width: 750px) {
  .quiz {
    flex-direction: column;
    width: 100%;
    padding: 10px 10px 0px;
    gap: 25px;
    align-items: center;
    justify-content: center;
  }

  .body {
    padding-bottom: 96px;
  }

  .leftSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0px;
    width: 100%;
    padding: 0px;
  }

  .imageContainer {
    width: 250px;
    height: 250px;
  }

  .questionImage {
    width: 250px;
    height: 250px;
  }

  .leftSectionButtonContainer,
  .hintText,
  .statusGroup {
    display: none;
  }

  .rightSection {
    width: 100%;
    gap: 25px;
  }

  .multipleChoiceQuestionsGroup,
  .resultContainer {
    gap: 15px;
  }

  .multipleChoiceOption,
  .correctAnswer,
  .incorrectAnswer {
    padding: 7px 15px 7px 25px;
  }

  .mobileSection {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .mobileLeftSectionButtonContainer {
    align-items: center;
  }

  .footer {
    display: flex;
    position: fixed;
    margin-top: auto;
  }
}

/************************************ Mobile ***********************************/
@media screen and (max-width: 450px) {
  .imageContainer {
    width: 200px;
    height: 200px;
  }
  
  .questionImage {
    width: 200px;
    height: 200px;
  }

  .multipleChoiceQuestionsGroup,
  .resultContainer {
    gap: 15px;
  }

  .multipleChoiceOption {
    padding: 5px 13px 5px 23px;
  }

  .correctAnswer,
  .incorrectAnswer {
    padding: 10px 13px 10px 23px;
  }
}
