/************************************ Desktop ***********************************/
.optionsMenu {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 100px;
  text-align: left;
}

.regularOptionsSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
}

.testTypeOptionGroup,
.timerOption {
  display: flex;
  align-items: center;
  gap: 75px;
}

.checkbox input[type="checkbox"]:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.advancedOptionsSection {
  width: 80%;
  background-color: var(--white);
  box-shadow: var(--dropshadow);
}

.advancedOptionsIcon {
  padding-right: 10px;
  height: 20px;
}

.questionAmountGroup {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 25px;
}

input[type="range"]::-webkit-slider-thumb {
  background: var(--primary);
}


/************************************ Tablet ***********************************/
@media screen and (max-width: 750px) {
  .testTypeOptionGroup,
  .timerOption {
    gap: 15px;
    padding-top: 25px;
  }

  .timerOption {
    padding-bottom: 25px;
  }

  .advancedOptionsSection {
    width: 100%;
  }
}



/************************************ Mobile ***********************************/
@media screen and (max-width: 450px) {
  .optionsMenu{
    gap: 50px;
  }

  .testTypeOptionGroup,
  .timerOption{
    flex-direction: column;
    align-items: start;
  }
  .titleGroup {
    padding-bottom: 50px;
  }

  .regularOptionsSection {
    gap: 10px;
  }

  .checkboxContainer {
    gap: var(--gap-base);
  }
  
  .questionsAmountParent {
    gap: var(--gap-lgi);
  }

  .advancedOptionsIcon {
    height: 15px;
    padding-right: 15px;
  }

  .startTestButton {
    padding: 15px 0px;
  }
}