.result {
  width: 100%;
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 150px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: var(--padding-Desktop-Section);
  gap: var(--gap-Desktop-Section);
}

.statsSection {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.statsSection .h1 {
  text-align: center;
}

.statsSection .subheading {
  text-align: center;
}

.specificScoreGroup {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.nextStepsSection {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
}

.buttonGroup {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: center;
}

/************************************ Tablet ***********************************/
@media screen and (max-width: 750px) {
  .body {
    padding-left: 0px;
    padding-right: 0px;
    width: 95%;
    padding-top: 100px;
    padding-bottom: var(--padding-Tablet-Section);
    gap: var(--gap-Tablet-Section);
  }
}

/************************************ Mobile ***********************************/
@media screen and (max-width: 450px) {
  .body {
    padding-top: 100px;
    padding-bottom: var(--padding-Mobile-Section);
    gap: var(--gap-Mobile-Section);
  }

  .statsSection {
    gap: 30px;
  }

  .generalScoreGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .specificScoreGroup {
    flex-direction: column;
    gap: 15px;
  }
}