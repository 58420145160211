.description {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  text-align: justify;
}

.descriptionLogoIcon {
  width: 30px;
  height: 30px;
}

/************************************ Tablet ***********************************/
@media screen and (max-width: 750px) {
  .descriptionLogoIcon {
    width: 25px;
    height: 25px;
  }
}

/************************************ Mobile ***********************************/
@media screen and (max-width: 450px) {
  .descriptionLogoIcon {
    width: 20px;
    height: 20px;
  }
}