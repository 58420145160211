.statusGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.timerGroup{
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  justify-content: center;
}

.rightGroup{
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 75%;
}

.ProgressBar{
  width: 100%;
}

.bottomGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 50px;
}

.amount {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.image {
  width: 10px;
  height: 10px;
}



/************************************ Tablet ***********************************/
@media screen and (max-width: 750px) {
  .rightGroup{
    gap: 10px;
  }

  .bottomGroup {
    gap: 25px;
  }
}
